export default
function () {
    if (document.getElementsByClassName('quiz-block').length) {

        const boxBtn = document.querySelector('.quiz-box')
        const interactive = document.querySelector('.interactive')
        const interactiveBtn = document.querySelector('.interactive__btn')
        const interactiveWrapper = document.querySelector('.interactive__wrapper')

        interactiveBtn.addEventListener('click', function () {
            boxBtn.style.display = 'block'
            interactiveWrapper.style.display = 'none';
            interactive.classList.add('active')
        })

        const quizData = [
            {
                question: "Who are you interested in?",
                a: "img/quiz-1-1.png",
                a_webp: "img/quiz-1-1.webp",
                a_text: "Man",
                b: "img/quiz-1-2.png",
                b_webp: "img/quiz-1-2.webp",
                b_text: "Woman",
                c: "img/quiz-1-3.png",
                c_webp: "img/quiz-1-3.webp",
                c_text: "No matter",

            },
            {
                question: "What’s your goal?",
                a: "img/quiz-2-1.png",
                a_webp: "img/quiz-2-1.webp",
                a_text: "Communication",
                b: "img/quiz-2-2.png",
                b_webp: "img/quiz-2-2.webp",
                b_text: "Friendship",
                c: "img/quiz-2-3.png",
                c_webp: "img/quiz-2-3.webp",
                c_text: "Something new",
            },
            {
                question: "What are your geo preferences?",
                a: "img/quiz-3-1.png",
                a_webp: "img/quiz-3-1.webp",
                a_text: "Europe",
                b: "img/quiz-3-2.png",
                b_webp: "img/quiz-3-2.webp",
                b_text: "LatAm",
                c: "img/quiz-3-3.png",
                c_webp: "img/quiz-3-3.webp",
                c_text: "asia",
                text: "",
            },
            {
                question: "What kind of people do you like?",
                a: "img/quiz-4-1.png",
                a_webp: "img/quiz-4-1.webp",
                a_text: "Shy",
                b: "img/quiz-4-2.png",
                b_webp: "img/quiz-4-2.webp",
                b_text: "Outgoing",
                c: "img/quiz-4-3.png",
                c_webp: "img/quiz-4-3.webp",
                c_text: "Placid",
                text: "",
            },
            {
                question: "What do you do on a Friday night?",
                a: "img/quiz-5-1.png",
                a_webp: "img/quiz-5-1.webp",
                a_text: "Watch movies",
                b: "img/quiz-5-2.png",
                b_webp: "img/quiz-5-2.webp",
                b_text: "Play games",
                c: "img/quiz-5-3.png",
                c_webp: "img/quiz-5-3.webp",
                c_text: "Read a book",
                text: "",
            },
            {
                question: "What’s the best option to have a rest?",
                a: "img/quiz-6-1.png",
                a_webp: "img/quiz-6-1.webp",
                a_text: "Gym",
                b: "img/quiz-6-2.png",
                b_webp: "img/quiz-6-2.webp",
                b_text: "cooking",
                c: "img/quiz-6-3.png",
                c_webp: "img/quiz-6-3.webp",
                c_text: "Travels",
                text: "",
            },
        ];


        const finishPopupAsian = document.querySelector('.popup-quiz-asian')
        const finishPopupAsianClose = document.querySelector('.popup-quiz__close--asian')

        finishPopupAsianClose.addEventListener("click", function () {
            finishPopupAsian.style.display = 'none';
            interactive.classList.remove("hide-after")
        })


        const finishPopupSlavic = document.querySelector('.popup-quiz-slavic')
        const finishPopupSlavicClose = document.querySelector('.popup-quiz__close--slavic')

        finishPopupSlavicClose.addEventListener("click", function () {
            finishPopupSlavic.style.display = 'none';
            interactive.classList.remove("hide-after")
        })


        const finishPopupLatina = document.querySelector('.popup-quiz-latina')
        const finishPopupLatinaClose = document.querySelector('.popup-quiz__close--latina')

        finishPopupLatinaClose.addEventListener("click", function () {
            finishPopupLatina.style.display = 'none';
            interactive.classList.remove("hide-after")
        })





        const imagesBlock = document.querySelector('.quiz-box__list');
        const animationCircle = document.querySelector('.loading');

        const counterItem = document.querySelector('.counter');
        const counterText = document.querySelector('.question-text');
        const counterTextTitle = document.querySelector('.counter-next');
        const answerElements = document.querySelectorAll('.answer');

        const questionElement = document.getElementById('question');
        const a_img = document.getElementById('a_img');
        const b_img = document.getElementById('b_img');
        const c_img = document.getElementById('c_img');

        const aWebp = document.getElementById('a_img-webp');
        const bWebp = document.getElementById('b_img-webp');
        const cWebp = document.getElementById('c_img-webp');

        const a_text = document.getElementById('a_text');
        const b_text = document.getElementById('b_text');
        const c_text = document.getElementById('c_text');

        let currentQuiz = 0;
        let score = 1;

        loadQuiz();

        function loadQuiz() {
            deselectAnswers();

            const currentQuizData = quizData[currentQuiz];

            questionElement.innerText = currentQuizData.question;

            a_img.src = currentQuizData.a;
            b_img.src = currentQuizData.b;
            c_img.src = currentQuizData.c;

            aWebp.srcset = currentQuizData.a_webp;
            bWebp.srcset = currentQuizData.b_webp;
            cWebp.srcset = currentQuizData.c_webp;

            a_text.textContent = currentQuizData.a_text;
            b_text.textContent = currentQuizData.b_text;
            c_text.textContent = currentQuizData.c_text;

            counterItem.innerText = score
        }

        function deselectAnswers() {
            answerElements.forEach(answerEl => answerEl.checked = false)
        }

        function getSelected() {
            answerElements.forEach(answerEl => {

                answerEl.addEventListener('click', function () {

                    if (answerEl.checked) {
                        currentQuiz++;
                        score++;

                        if (currentQuiz < quizData.length) {
                            loadQuiz();
                        } else {
                            questionElement.textContent = 'Results Preparation'
                            imagesBlock.style.display = 'none'
                            animationCircle.style.display = 'block'
                            createProgress();
                            counterTextTitle.style.color = 'white'

                            setTimeout(function () {
                                const pageAttribute = document.querySelector('[data-page]').getAttribute('data-page');

                                const indexArr=[
                                    {name:"Bravodate",link:"https://bravodate.com/land/sp/6ee4935b", className: 'site-bravodate',},
                                    {name:"La-date",link:"https://la-date.com/land/sp/1f43b0af", className: 'site-Ladate',},
                                    {name:"Orchidromance",link:"https://orchidromance.com/land/sp/40171b46", className: 'site-orchidromance',},
                                    {name:"AmourFeel",link:"https://amourfeel.com/land/sp/263fb41c", className: 'site-Amourfeel',},
                                    {name:"Rondevo",link:"https://rondevo.com/land/sp/0fe765b5", className: 'site-rondevo',},];

                                const asianArr=[
                                    {name:"Orchidromance",link:"https://orchidromance.com/land/sp/40171b46", className: 'site-orchidromance',},
                                    {name:"EasternHoneys",link:"https://easternhoneys.com/land/sp/d4734ed9", className: 'site-easternhoneys',},
                                    {name:"AsianMelodies",link:"https://asianmelodies.com/land/sp/20598710", className: 'site-Asianmelodies',},
                                    {name:"asianfeels",link:"https://asianfeels.com/land/sp/2f244ce1", className: 'site-AsianFeels',},
                                    {name:"loverwhirl",link:"https://loverwhirl.com/land/sp/214c733b", className: 'site-loverwhirl',},];

                                const slavicArr=[
                                    {name:"Bravodate",link:"https://bravodate.com/land/sp/6ee4935b", className: 'site-bravodate',},
                                    {name:"JollyRomance",link:"https://jollyromance.com/land/sp/b435e6f4", className: 'site-Jollyromance',},
                                    {name:"AmourFactory",link:"https://amourfactory.com/land/sp/4e8f8766", className: 'site-amourfactory',},
                                    {name:"AmourFeel",link:"https://amourfeel.com/land/sp/263fb41c", className: 'site-Amourfeel',},
                                    {name:"Rondevo",link:"https://rondevo.com/land/sp/0fe765b5", className: 'site-rondevo',},
                                ];
                                const latinaArr=[
                                    {name:"La-date",link:"https://la-date.com/land/sp/1f43b0af", className: 'site-Ladate',},
                                    {name:"LoveFort",link:"https://lovefort.com/land/sp/7f3e0941", className: 'site-Lovefort',},
                                    {name:"LATINFEELS",link:"https://latinfeels.com/land/sp/a5fb0234", className: 'site-Latinfeels',},
                                    {name:"Funchatt",link:"https://funchatt.com/land/sp/b4b4d065", className: 'site-Funchatt',},
                                ];

                                const finish = document.querySelector('.finish')
                                const finishTitle = document.querySelector('.finish-title span');
                                const finishImg = document.querySelector('.finish-img')
                                const finishLInk = document.querySelector('.finish__btn')
                                const finishBoxImg = document.querySelector('.finish-box__img')

                                const finishFade1 = document.querySelector('.finish-box__fade-1')
                                const finishFade2 = document.querySelector('.finish-box__fade-2')
                                const finishFade3 = document.querySelector('.finish-box__fade-3')
                                const finishFade4 = document.querySelector('.finish-box__fade-4')


                                let randomItem;
                                let randomImg;

                                switch (pageAttribute) {
                                    case 'index':
                                        randomItem = indexArr[Math.floor(Math.random() * indexArr.length)];
                                        randomImg = 'index'
                                        break;
                                    case 'asian':
                                        randomItem = asianArr[Math.floor(Math.random() * asianArr.length)];
                                        randomImg = 'asian'
                                        break;
                                    case 'slavic':
                                        randomItem = slavicArr[Math.floor(Math.random() * slavicArr.length)];
                                        randomImg = 'index'
                                        break;
                                    case 'latina':
                                        randomItem = latinaArr[Math.floor(Math.random() * latinaArr.length)];
                                        randomImg = 'latina'
                                        break;
                                    default:
                                        randomItem = indexArr[Math.floor(Math.random() * indexArr.length)];
                                        randomImg = 'index'
                                }

                                animationCircle.style.display = 'none'
                                counterText.style.display = 'none'

                                finish.style.display = 'block'
                                interactive.firstElementChild.style.paddingBottom = '0'

                                // console.log('name', randomItem.name, 'link', randomItem.link)

                                questionElement.textContent = 'Your Result'
                                finishTitle.textContent = randomItem.name
                                finishImg.setAttribute('src', `img/${randomItem.name}.svg`)
                                finishBoxImg.setAttribute('src', `img/${randomImg}-girls.png`)
                                finishBoxImg.previousElementSibling.setAttribute('srcset', `img/${randomImg}-girls-mob.png`)
                                finishLInk.setAttribute('href', `${randomItem.link}`)
                                finishLInk.classList.add(`${randomItem.className}`)

                                setTimeout(function () {
                                    fadeIn(finishFade1)
                                }, 500)

                                setTimeout(function () {
                                    fadeIn(finishFade2)
                                    fadeIn(finishFade3)
                                }, 1500)

                                setTimeout(function () {
                                    fadeIn(finishFade4)
                                    startCounterCircle()
                                    finishBoxImg.style.bottom = '0'
                                    finishBoxImg.style.opacity = '1'
                                }, 2500)

                                setTimeout(function () {

                                    const popupQuizImg = document.querySelector('.popup-quiz-img')



                                    switch (pageAttribute) {
                                        case 'index':
                                             fadeIn(finishPopupSlavic)
                                             interactive.classList.add("hide-after")

                                            break;
                                        case 'asian':
                                             fadeIn(finishPopupAsian)
                                             interactive.classList.add("hide-after")

                                            break;
                                        case 'slavic':
                                             fadeIn(finishPopupSlavic)
                                             interactive.classList.add("hide-after")

                                            break;
                                        case 'latina':
                                            fadeIn(finishPopupLatina)
                                            interactive.classList.add("hide-after")

                                            break;
                                        default:
                                             fadeIn(finishPopupSlavic)
                                             interactive.classList.add("hide-after")

                                    }
                                }, 5700)
                            }, 5700)
                        }
                    }
                })
            });
        }

        getSelected()

        const RadialProgress = function (size, barSize, barColor, backgroundColor, textColor, zIndex) {
            this.radialProgress = document.createElement('div');
            this.style = document.createElement('style');
            this.progress = 0;
            const requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame;

            barSize = (barSize % 2 === 1) ? (barSize + 1) : barSize;

            const innerSize = size - barSize;
            const innerMargin = barSize / 2;

            this.radialProgress.className = 'radial-progress';
            this.radialProgress.innerHTML = '<div class="inner-circle">' +
                '<div class="progress">0%<' + '/div>' +
                '</div>' +
                '<div class="outer-circle">' +
                '<div class="mask full">' +
                '<div class="fill"></div>' +
                '</div>' +
                '<div class="mask">' +
                '<div class="fill"></div>' +
                '<div class="fill fix"></div>' +
                '</div>' +
                '</div>';

            this.style.type = 'text/css';
            this.style.innerHTML = '.radial-progress {' +
                'width:' + size + 'px;' +
                'height: ' + size + 'px;' +
                'position: relative;' +
                'margin: auto;' +
                'top: 0; right: 0; bottom: 0; left: 0;' +
                'z-index: ' + zIndex + ';' +
                'background-color: #DDD;' +
                'border-radius: 50%;' +
                'box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);' +
                '}' +
                '.radial-progress .inner-circle {' +
                'width: ' + innerSize + 'px;' +
                'height: ' + innerSize + 'px;' +
                'position: absolute;' +
                'margin-top: ' + innerMargin + 'px;' +
                'margin-left: ' + innerMargin + 'px;' +
                'background-color: ' + backgroundColor + ';' +
                'border-radius: 50%;' +
                'z-index: 10;' +
                '}' +
                '.radial-progress .inner-circle .progress {' +
                'height: 60px;' +
                'font-weight: bold;' +
                'position: absolute;' +
                'font-size: 40px;' +
                'margin: auto;' +
                'top: 0; right: 0; bottom: 0; left: 0;' +
                'text-align: center;' +
                'color: ' + textColor + ';' +
                '}' +
                '.radial-progress .outer-circle .mask,' +
                '.radial-progress .outer-circle .fill {' +
                'width: ' + size + 'px;' +
                'height: ' + size + 'px;' +
                'position: absolute;' +
                'border-radius: 50%;' +
                '-webkit-backface-visibility: hidden;' +
                '}' +
                '.radial-progress .outer-circle .mask {' +
                'clip: rect(0px, ' + size + 'px, ' + size + 'px, ' + size / 2 + 'px);' +
                '}' +
                '.radial-progress .outer-circle .mask .fill {' +
                'clip: rect(0px, ' + size / 2 + 'px, ' + size + 'px, 0px);' +
                'background-color: ' + barColor + ';' +
                'border-radius: ' + '20' + 'px, ' + ';' +
                '}';

            document.querySelector('.loading-bar').appendChild(this.style)
            document.querySelector('.loading-bar').appendChild(this.radialProgress);

            this.setProgress = function (progress, duration) {
                progress = (progress > 100) ? 100 : progress;
                const self = this;
                const $maskFull = this.radialProgress.getElementsByClassName('mask full')[0];
                const $fill = this.radialProgress.getElementsByClassName('fill');
                const $fillFix = this.radialProgress.getElementsByClassName('fill fix')[0];
                const $progress = this.radialProgress.getElementsByClassName('progress')[0];
                const deltaProgress = (progress - this.progress) / (duration * 60);

                function step() {
                    self.progress += deltaProgress;
                    self.progress = (self.progress > progress) ? progress : self.progress;
                    const rotate = self.progress * 1.8;
                    $maskFull.style.transform = 'rotate(' + rotate + 'deg)';
                    $progress.innerHTML = self.progress.toFixed() + '%';

                    for (let i = 0; i < $fill.length; ++i) {
                        $fill[i].style.transform = 'rotate(' + rotate + 'deg)';
                    }

                    $fillFix.style.transform = 'rotate(' + 2 * rotate + 'deg)';

                    if (self.progress < progress) {
                        requestAnimationFrame(step);
                    }
                }

                requestAnimationFrame(step);
            };
        };

        function createProgress() {
            const progress = new RadialProgress(175, 8, '#FF9518', '#96c9e8', '#fff', 10);

            setTimeout(function () {
                progress.setProgress(75, 2);
            }, 500);

            setTimeout(function () {
                progress.setProgress(100, 3);
            }, 2500);
        }
    }

    function fadeIn(el, display) {
        el.style.opacity = 0;
        el.style.display = display || "block";
        (function fade() {
            var val = parseFloat(el.style.opacity);
            if (!((val += .1) > 1)) {
                el.style.opacity = val;
                requestAnimationFrame(fade);
            }
        })();
    }

    function startCounterCircle() {
        const svgCircle = document.querySelector('.percentage')

        const total = 300
        let counter = 0

        for (let i = 0; i < total; ++i) {
            setTimeout(function () {
                counter++;
                let result = Math.ceil(94 * counter / total);
                svgCircle.textContent = result + '%'
            }, (Math.random() * 2600))
        }
    }
}


