export default function ethnicSearchParams() {
  addMarketingParamsToLinks()
  ethnicContentInit()
}

function addMarketingParamsToLinks() {

  const marketingParams = window.location.search

  if (marketingParams) {
    const links = document.querySelectorAll('a')

    links.forEach((link) => {
      const newPath = link.href + marketingParams
      link.href = newPath
    })
  }
}

function ethnicContentInit() {
  const params = new URLSearchParams(window.location.search)
  const keyword = params.get('keyword')

  const ethnics = {
    0: 'International',
    1: 'European',
    2: 'Slavic',
    3: 'Asian',
    4: 'Latin'
  }

  const KEYWORDS = {
    0: {
      subEthnicKeyWords: [
        {
          key: 'international',
          text: 'International'
        },
        {
          overseas: 'overseas',
          text: 'International'
        },
        {
          key: 'interracial',
          text: 'International'
        },
        {
          key: 'dutch',
          text: 'Dutch'
        },
        {
          key: 'german',
          text: 'German'
        },
        {
          key: 'french',
          text: 'French'
        },
        {
          key: 'france',
          text: 'French'
        },
        {
          key: 'italy',
          text: 'Italian'
        },
        {
          key: 'italian',
          text: 'Italian'
        },
        {
          key: 'norway',
          text: 'Norwegian'
        },
        {
          key: 'norwegian',
          text: 'Norwegian'
        },
        {
          key: 'spain',
          text: 'Spanish'
        },
        {
          key: 'spanish',
          text: 'Spanish'
        },
        {
          key: 'portugal',
          text: 'Portuguese'
        },
        {
          key: 'portuguese',
          text: 'Portuguese'
        },
        {
          key: 'britain',
          text: 'British'
        },
        {
          key: 'british',
          text: 'British'
        },
        {
          key: 'greek',
          text: 'Greek'
        },
        {
          key: 'greece',
          text: 'Greek'
        },
        {
          key: 'poland',
          text: 'Polish',
        },
        {
          key: 'polish',
          text: 'Polish',
        },
        {
          key: 'ukraine',
          text: 'Ukrainian',
        },
        {
          key: 'ukrainian',
          text: 'Ukrainian',
        },
        {
          key: 'belarus',
          text: 'Belarusian',
        },
        {
          key: 'russia',
          text: 'Russian',
        },
        {
          key: 'kazakh',
          text: 'Kazakh',
        },
        {
          key: 'armenia',
          text: 'Armenian',
        },
        {
          key: 'georgia',
          text: 'Georgian',
        },
        {
          key: 'romania',
          text: 'Romanian',
        },
        {
          key: 'uzbek',
          text: 'Uzbek',
        },
        {
          key: 'turkmen',
          text: 'Turkmen',
        },
        {
          key: 'azerbaijan',
          text: 'Azerbaijani',
        },
        {
          key: 'moldavia',
          text: 'Moldavian',
        },
        {
          key: 'latvia',
          text: 'Latvian',
        },
        {
          key: 'lithuania',
          text: 'Lithuanian',
        },
        {
          key: 'estonia',
          text: 'Estonian',
        },
        {
          key: 'bulgaria',
          text: 'Bulgarian',
        },
        {
          key: 'slovak',
          text: 'Slovakian',
        },
        {
          key: 'slovenia',
          text: 'Slovenian',
        },
        {
          key: 'bosnia',
          text: 'Bosnian',
        },
        {
          key: 'czech',
          text: 'Czech',
        },
        {
          key: 'japan',
          text: 'Japanese',
        },
        {
          key: 'china',
          text: 'Chinese',
        },
        {
          key: 'chinese',
          text: 'Chinese',
        },
        {
          key: 'cambodia',
          text: 'Cambodian',
        },
        {
          key: 'malaysia',
          text: 'Malaysian',
        },
        {
          key: 'hong kong',
          text: 'Hong Kong',
        },
        {
          key: 'korea',
          text: 'Korean',
        },
        {
          key: 'pina',
          text: 'Filipino',
        },
        {
          key: 'filipino',
          text: 'Filipino',
        },
        {
          key: 'thai',
          text: 'Thai',
        },
        {
          key: 'vietnam',
          text: 'Vietnam',
        },
        {
          key: 'viet',
          text: 'Vietnam',
        },
        {
          key: 'seoul',
          text: 'Korean',
        },
        {
          key: 'tokyo',
          text: 'Japanese',
        },
        {
          key: 'indonesia',
          text: 'Indonesian',
        },
        {
          key: 'singapore',
          text: 'Singaporean',
        },
        {
          key: 'argentina',
          text: 'Argentinian',
        },
        {
          key: 'argentinian',
          text: 'Argentinian',
        },
        {
          key: 'colombia',
          text: 'Colombian',
        },
        {
          key: 'brazil',
          text: 'Brazilian',
        },
        {
          key: 'mexica',
          text: 'Mexican',
        },
        {
          key: 'mexico',
          text: 'Mexican',
        },
        {
          key: 'venezuela',
          text: 'Venezuelan',
        },
        {
          key: 'costa rica',
          text: 'Costa Rican',
        },
        {
          key: 'puerto rico',
          text: 'Puerto Rican',
        },
        {
          key: 'puerto rican',
          text: 'Puerto Rican',
        },
        {
          key: 'dominican',
          text: 'Dominican',
        },
        {
          key: 'haiti',
          text: 'Haitian',
        },
        {
          key: 'salvador',
          text: 'Salvadoran',
        },
        {
          key: 'bolivia',
          text: 'Bolivian',
        },
        {
          key: 'chile',
          text: 'Chilean',
        },
        {
          key: 'peru',
          text: 'Peruvian',
        },
        {
          key: 'guyana',
          text: 'Guyanese',
        },
        {
          key: 'guyanese',
          text: 'Guyanese',
        },
        {
          key: 'guatemala',
          text: 'Guatemalan',
        },
        {
          key: 'honduras',
          text: 'Honduran',
        },
        {
          key: 'honduran',
          text: 'Honduran',
        },
        {
          key: 'cuba',
          text: 'Cuban',
        },
        {
          key: 'cuban',
          text: 'Cuban',
        },
      ],
    },
    1: {
      subEthnicKeyWords: [
        {
          key: 'dutch',
          text: 'Dutch',
        },
        {
          key: 'german',
          text: 'German',
        },
        {
          key: 'french',
          text: 'French',
        },
        {
          key: 'france',
          text: 'French',
        },
        {
          key: 'italy',
          text: 'Italian',
        },
        {
          key: 'italian',
          text: 'Italian',
        },
        {
          key: 'norway',
          text: 'Norwegian',
        },
        {
          key: 'norwegian',
          text: 'Norwegian',
        },
        {
          key: 'spain',
          text: 'Spanish',
        },
        {
          key: 'spanish',
          text: 'Spanish',
        },
        {
          key: 'portugal',
          text: 'Portuguese',
        },
        {
          key: 'portuguese',
          text: 'Portuguese',
        },
        {
          key: 'britain',
          text: 'British',
        },
        {
          key: 'british',
          text: 'British',
        },
        {
          key: 'greek',
          text: 'Greek',
        },
        {
          key: 'greece',
          text: 'Greek',
        },
      ]
    },
    2: {
      subEthnicKeyWords: [
        {
          key: 'poland',
          text: 'Polish',
        },
        {
          key: 'polish',
          text: 'Polish',
        },
        {
          key: 'ukraine',
          text: 'Ukrainian',
        },
        {
          key: 'ukrainian',
          text: 'Ukrainian',
        },
        {
          key: 'belarus',
          text: 'Belarusian',
        },
        {
          key: 'russia',
          text: 'Russian',
        },
        {
          key: 'kazakh',
          text: 'Kazakh',
        },
        {
          key: 'armenia',
          text: 'Armenian',
        },
        {
          key: 'georgia',
          text: 'Georgian',
        },
        {
          key: 'romania',
          text: 'Romanian',
        },
        {
          key: 'uzbek',
          text: 'Uzbek',
        },
        {
          key: 'turkmen',
          text: 'Turkmen',
        },
        {
          key: 'azerbaijan',
          text: 'Azerbaijani',
        },
        {
          key: 'moldavia',
          text: 'Moldavian',
        },
        {
          key: 'latvia',
          text: 'Latvian',
        },
        {
          key: 'lithuania',
          text: 'Lithuanian',
        },
        {
          key: 'estonia',
          text: 'Estonian',
        },
        {
          key: 'bulgaria',
          text: 'Bulgarian',
        },
        {
          key: 'slovak',
          text: 'Slovakian',
        },
        {
          key: 'slovenia',
          text: 'Slovenian',
        },
        {
          key: 'bosnia',
          text: 'Bosnian',
        },
        {
          key: 'czech',
          text: 'Czech',
        },
      ]
    },
    3: {
      subEthnicKeyWords: [
        {
          key: 'japan',
          text: 'Japanese',
        },
        {
          key: 'china',
          text: 'Chinese',
        },
        {
          key: 'chinese',
          text: 'Chinese',
        },
        {
          key: 'cambodia',
          text: 'Cambodian',
        },
        {
          key: 'malaysia',
          text: 'Malaysian',
        },
        {
          key: 'hong kong',
          text: 'Hong Kong',
        },
        {
          key: 'korea',
          text: 'Korean',
        },
        {
          key: 'pina',
          text: 'Filipino',
        },
        {
          key: 'filipino',
          text: 'Filipino',
        },
        {
          key: 'thai',
          text: 'Thai',
        },
        {
          key: 'vietnam',
          text: 'Vietnam',
        },
        {
          key: 'viet',
          text: 'Vietnam',
        },
        {
          key: 'seoul',
          text: 'Korean',
        },
        {
          key: 'tokyo',
          text: 'Japanese',
        },
        {
          key: 'indonesia',
          text: 'Indonesian',
        },
        {
          key: 'singapore',
          text: 'Singaporean',
        },
      ]
    },
    4: {
      subEthnicKeyWords: [
        {
          key: 'argentina',
          text: 'Argentinian',
        },
        {
          key: 'argentinian',
          text: 'Argentinian',
        },
        {
          key: 'colombia',
          text: 'Colombian',
        },
        {
          key: 'brazil',
          text: 'Brazilian',
        },
        {
          key: 'mexica',
          text: 'Mexican',
        },
        {
          key: 'mexico',
          text: 'Mexican',
        },
        {
          key: 'venezuela',
          text: 'Venezuelan',
        },
        {
          key: 'costa rica',
          text: 'Costa Rican',
        },
        {
          key: 'puerto rico',
          text: 'Puerto Rican',
        },
        {
          key: 'puerto rican',
          text: 'Puerto Rican',
        },
        {
          key: 'dominican',
          text: 'Dominican',
        },
        {
          key: 'haiti',
          text: 'Haitian',
        },
        {
          key: 'salvador',
          text: 'Salvadoran',
        },
        {
          key: 'bolivia',
          text: 'Bolivian',
        },
        {
          key: 'chile',
          text: 'Chilean',
        },
        {
          key: 'peru',
          text: 'Peruvian',
        },
        {
          key: 'guyana',
          text: 'Guyanese',
        },
        {
          key: 'guyanese',
          text: 'Guyanese',
        },
        {
          key: 'guatemala',
          text: 'Guatemalan',
        },
        {
          key: 'honduras',
          text: 'Honduran',
        },
        {
          key: 'honduran',
          text: 'Honduran',
        },
        {
          key: 'cuba',
          text: 'Cuban',
        },
        {
          key: 'cuban',
          text: 'Cuban',
        },
      ]
    },
  }
  const PERSONS = [
    {
      key: 'girlfriend',
      text: 'Girls',
    },
    {
      key: 'girl',
      text: 'Girls',
    },
    {
      key: 'lady',
      text: 'Ladies',
    },
    {
      key: 'ladies',
      text: 'Ladies',
    },
    {
      key: 'penpal',
      text: 'Pen Pals',
    },
    {
      key: 'pen pal',
      text: 'Pen Pals',
    },
    {
      key: 'singles',
      text: 'Singles',
    },
    {
      key: 'lover',
      text: 'Lover',
    },
    {
      key: 'boyfriend',
      text: 'Boyfriend',
    },
    {
      key: 'boy',
      text: 'Boys',
    },
    {
      key: 'fellow',
      text: 'Fellow',
    },
    {
      key: 'guy',
      text: 'Guy',
    },
    {
      key: 'man',
      text: 'Men',
    },
    {
      key: 'men',
      text: 'Men',
    },
    {
      key: 'woman',
      text: 'Women',
    },
    {
      key: 'women',
      text: 'Women',
    },
    {
      key: 'gay',
      text: 'Gay',
    },
    {
      key: 'tranny',
      text: 'Transsexuals',
    },
    {
      key: 'ts',
      text: 'Transsexuals',
    },
    {
      key: 'transgender',
      text: 'Transsexuals',
    },
    {
      key: 'bi',
      text: 'Bisexuals',
    },
    {
      key: 'bisexual',
      text: 'Bisexuals',
    },
  ]
  const PRODUCT = [
    {
      key: 'app',
      text: 'Dating Apps',
    },
    {
      key: 'site',
      text: 'Dating Sites',
    },
  ]
  const ACTIONS = [
    {
      key: 'meet',
      text: 'Meet',
    },
    {
      key: 'find',
      text: 'Find',
    },
    {
      key: 'date',
      text: 'Meet',
    },
    {
      key: 'talk',
      text: 'Talk',
    },
    {
      key: 'chat',
      text: 'Chat',
    },
  ]

  const findEthnicAndSubEthnic = (type) => {
    let value = window.location.href

    const matchSubEthnic = (ethnicIndex) => KEYWORDS[ethnicIndex].subEthnicKeyWords.find(
      (item) => keyword && keyword.includes(item.key)
    )?.text ?? ''

    const matchEthnicInternational = () => KEYWORDS[0].subEthnicKeyWords.find(
      (item) => keyword && keyword.includes(item.key)
    )?.text ?? KEYWORDS[0].text;
    const checkType = (index) => {
      if (type === 'subEthnic' && value.includes('slavic') && !!matchSubEthnic(1)) {
        return matchSubEthnic(1)
      } else if (type === 'subEthnic' && value.includes('slavic')) {
        return matchSubEthnic(2)
      } else if (type === 'subEthnic' && value.includes('asian')) {
        return matchSubEthnic(3)
      } else if (type === 'subEthnic' && value.includes('latina')) {
        return matchSubEthnic(4)
      } else if (type === 'subEthnic' && !['latin', 'asian', 'slavic'].includes(value)) {
        return matchSubEthnic(0)
      }

      if (type === 'ethnic' && !['latin', 'asian', 'slavic'].some(item => value.includes(item))) {
        if (matchEthnicInternational() && !!matchSubEthnic(1)) {
          return ethnics[1]
        } else if (matchEthnicInternational() && !!matchSubEthnic(2)) {
          return ethnics[2]
        } else if (matchEthnicInternational() && !!matchSubEthnic(3)) {
          return ethnics[3]
        } else if (matchEthnicInternational() && !!matchSubEthnic(4)) {
          return ethnics[4]
        } else {
          return ethnics[0]
        }
      } else {
        return ethnics[index]
      }
    }

    switch (true) {
      case value.includes('slavic') && !!matchSubEthnic(1):
        return checkType(1)
      case value.includes('slavic'):
        return checkType(2)
      case value.includes('asian'):
        return checkType(3)
      case value.includes('latina'):
        return checkType(4)
      default:
        return checkType(0)
    }
  }

  let ethnic = findEthnicAndSubEthnic('ethnic')
  let subEthnic = findEthnicAndSubEthnic('subEthnic')
  let person = 'Women'
  let product = 'Dating Sites'
  let action = 'Find'

  PERSONS.map((item) => {
    if (keyword && keyword.includes(item.key)) {
      person = item.text
    }
  })

  PRODUCT.map((item) => {
    if (keyword && keyword.includes(item.key)) {
      product = item.text
    }
  })

  ACTIONS.map((item) => {
    if (keyword && keyword.includes(item.key)) {
      action = item.text
    }
  })

  const updateValuesInDOM = () => {
    const ethnicArray = document.querySelectorAll('.content-load_ethnic')
    const subEthnicArray = document.querySelectorAll('.content-load_subethnic')
    const personArray = document.querySelectorAll('.content-load_person')
    const productArray = document.querySelectorAll('.content-load_product')
    const actionArray = document.querySelectorAll('.content-load_action')

    if (ethnicArray.length) {
      ethnicArray.forEach((item) => {
        item.textContent = ethnic
      })
    }

    if (personArray.length) {
      personArray.forEach((item) => {
        item.textContent = person
      })
    }

    if (productArray.length) {
      productArray.forEach((item) => {
        item.textContent = product
      })
    }

    if (actionArray.length) {
      actionArray.forEach((item) => {
        item.textContent = action
      })
    }

    if (subEthnicArray.length) {
      subEthnicArray.forEach((item) => {
        item.textContent = subEthnic
      })
    }
  }

  setTimeout(() => {
    const subEthnic = document.getElementById('subEthnic');

    if (subEthnic && subEthnic.textContent.trim().length === 0) {
      subEthnic.style.display='none'
    }
  }, 0)

  updateValuesInDOM()
}
