import scroll from './helpers/smooth-scroll.js';
import burger from "./modules/burger-menu";
import accordion from "./modules/questions";
import blogSlider from "./modules/blog-post";
import quiz from "./modules/quiz";
import catalog from "./modules/catalog";
import rate from "./modules/rate";
import utm from "./modules/utm";
import date from "./modules/update-date";
import promoDate from "./modules/update-promo-year";
import ethnicSearchParams from "./modules/ethnicSearchParams";

function main() {
    accordion()
    blogSlider()
    rate()
    quiz()
    catalog()
}

burger()
scroll()
utm()
promoDate()

if (document.documentElement.clientWidth < 480) {
    window.addEventListener('scroll',
        function () {
            return setTimeout(main, 1000);
        }, {
            once: true,
            passive: true
        });
} else {
    main();
}

date()
ethnicSearchParams()
